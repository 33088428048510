<template>
  <div>
    <div class="head">
      <div class="imgint">
        <img src=".././assets/img/groupone.png" style="cursor: pointer" @click="toHome" />
        <div class="head-span">
          <!-- <router-link to="beacon" :class="{'active': active === '/beacon'}"   class="dingw">
      <span>烽台</span>
      <div class="xhaunx"></div>
    </router-link>
    <router-link to="DigitalTwinning" :class="{'active': active === '/DigitalTwinning'}"   class="dingw">
      <span>数字孪生</span>
      <div class="xhaunx"></div>
    </router-link> -->
          <!-- <router-link to="project" :class="{ active: active === '/tech/project' }" class="dingw">
            <span>产品方案</span>
            <div class="xhaunx"></div>
          </router-link> -->
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" background-color="#fff" active-text-color="#FF892E">
            <el-menu-item index="1">
              <router-link to="/tech"> 首页 </router-link>
            </el-menu-item>
            <el-submenu index="1">
              <template slot="title">产品方案</template>
              <div class="my-menu">
                <div class="menu-grid ">
                  <div class="hand flex-center">大数据平台</div>
                  <div class="hand flex-center">专注央国企运营管理</div>
                  <div class="hand flex-center">智慧交通</div>
                </div>
                <div class="menu-grid">
                  <div class="line"></div>
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
                <div class="menu-grid flex-center" style="margin-top: 5px;">
                  <div class="text">保障大数据平台</div>
                  <div class="text">协同办公平台</div>
                  <div class="text">内河湖海航运监管平台</div>  
                </div>
                <div class="menu-grid flex-center">
                  <div class="text">应急大数据平台</div>
                  <div class="text">项目管理平台</div>
                  <div class="text">
                    <router-link to="airmanage" class="text"> 机场无人机空域监管平台 </router-link>
                  </div>
                </div>
                <div class="menu-grid flex-center">
                  <div class="text">能源大数据平台</div>
                  <div class="text">营销运营平台</div>
                  <div class="text"></div>
                </div>
                <div class="menu-grid flex-center">
                  <div class="text">旅游大数据平台</div>
                  <div class="text"> 资产管理平台</div>
          
                  <div class="text"> </div>
                </div>
                <div class="menu-grid flex-center">
                  <div class="text"></div>
                  <div class="text ml20">
                    <router-link to="project" class="text"> 融资资金债务管理平台 </router-link>
                  </div>
                  <div class="text"> </div>
                </div>
              </div>
            </el-submenu>
            <!-- <el-menu-item index="3">
              <router-link to="service"> 技术服务</router-link>
            </el-menu-item> -->
            <el-menu-item index="4">
              <router-link to="case">客户案例</router-link>
            </el-menu-item>
            <el-menu-item index="5">
              <router-link to="about">关于我们</router-link>
            </el-menu-item>
          </el-menu>

          <!-- <router-link to="DigitalTwinning" :class="{'active': active === '/DigitalTwinning'}"   class="dingw">
      <span>数字孪生</span>
      <div class="xhaunx"></div>
    </router-link>

    <router-link to="solution"  :class="{'active': active === '/solution'}"  class="dingw">
      <span>数字化解决方案</span>
        <div class="xhaunx"></div>
    </router-link> -->

        </div>
      </div>
      <div class="imgring">
        <!-- <img src=".././assets/img/grouptwo.png" /> -->
        <p class="b-number-font">0731-89917763</p>
      </div>
    </div>
    <router-view></router-view>

    <div class="imgl">
      <div>
        <img src=".././assets/img/groupthree.png" width="100%" />
      </div>
      <div class="img-booton">
        <div class="sptop">
          <p>行业解决方案</p>
          <ul>
            <li>数字通信</li>
            <li>数字城市</li>
            <li>数字电网</li>
            <!-- <li>数字工业制造</li> -->
          </ul>
        </div>
        <div class="sptop">
          <p>业务及产品</p>
          <ul>
            <li>数字化咨询</li>
            <li>数字孪生</li>
            <li>数字化解决方案</li>
          </ul>
        </div>
        <div class="sptop">
          <p>关于金锋</p>
          <ul>
            <li>公司介绍</li>
            <li>加入金锋</li>
            <li>联系我们</li>
          </ul>
        </div>
        <div class="fontimg">
          <img src=".././assets/img/groupfour.png" />
        </div>
      </div>

      <div class="copy_right">
        <span>Copyright © 2018 www.goldbeacon.cn. All Rights Reserved
          &nbsp;&nbsp;&nbsp;湖南金烽信息科技有限公司版权所有
          &nbsp;&nbsp;&nbsp;<a @click="skip" class="skipCls">湘ICP备18016577号-1</a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: '/tech/home',
      activeIndex: '1'
    }
  },
  created() {
    this.active=this.$route.path
    // if (this.$router.path !== '/') { this.$router.replace('/') }
  },
  watch: {
    $route(to) {
      console.log('监听',to.path)
      this.active=to.path
    }
  },
  methods: {
    toHome() {
      this.$router.push('/')
    },
    skip() {
      window.open('https://beian.miit.gov.cn/','_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.skipCls {
  cursor: pointer;
}

.imgint {
  display: flex;

  img {
    width: 153px;
    object-fit: contain;
    margin-right: 50px;
  }
}

a {
  text-decoration: none;
}

.head {
  width: 1424px;
  margin: auto;
  height: 96px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  // width: 1900px;
  // width: 100%;
}

span {
  padding: 0 32px;
  font-size: 22px;
  font-family: Microsoft YaHei;
}

.head-span {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.imgring {
  // padding-left: 57px;
  img {
    width: 220px;
  }
}

.dingw {
  display: block;
  position: relative;
  line-height: 96px;
  color: #000;

  .xhaunx {
    display: none;
  }
}

.active {
  color: #ff892e;

  .xhaunx {
    display: block;
  }
}

.xhaunx {
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: #ff892e;
  top: 93px;
}

.imgl {
  position: relative;
  font-size: 0;
}

.img-booton {
  position: absolute;
  bottom: 140px;
  right: 293px;
  display: flex;
  justify-content: center;

  li {
    list-style-type: none;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 32px;
    color: #c9c9c9;
    opacity: 1;
  }

  .sptop {
    p {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #f9f9f9;
      opacity: 1;
    }

    ul {
      margin-top: 20px;
    }

    margin-right: 90px;
    padding-bottom: 20px;
  }
}

.fontimg {
  img {
    width: 114px;
  }
}

.copy_right {
  width: 100%;
  height: 39px;
  background: #1c2834;
  color: #75818a;
  line-height: 39px;
  text-align: center;
  font-size: 14px;

  span {
    font-size: 14px;
  }
}

.el-menu-demo {
  ::v-deep {
    .el-menu-item,
    .el-submenu__title {
      height: 99px;
      display: flex;
      align-items: center;
      padding: 0 1.66667vw;
      font-size: 23px;
      font-family: Microsoft YaHei;
      color: #000;

      i {
        display: none;
      }
    }

    .el-submenu.is-active .el-submenu__title,
    .el-menu-item.is-active {
      border-bottom: 5px solid #ff892e !important;
    }
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-menu {
  height: 357px;
  .menu-grid {
    display: grid;
    grid-template-columns: repeat(3, 126px);
    justify-content: left;
    grid-gap: 108px;
    margin-left: 575px;
    .hand {
      height: 85px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 500;
      font-size: 22px;
      color: #252d47;
      white-space: nowrap;

    }
    .line {
      width: 100%;
      height: 2px;
      background: #eeeeee;
      border-radius: 0px 0px 0px 0px;
    }
    .text {
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 18px;
      color: #536087;
      padding: 14px 0px;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
.el-menu-item:hover {
  background: transparent !important;
  .text {
    color: #ff892e !important;
  }
  // text-decoration: underline;
}
</style>
<style lang="scss">
.el-menu--popup {
  min-width: 100vw !important;
  margin-top: 1px !important;
  text-align: center;
}
.el-menu--horizontal {
  left: 0px !important;
}
.b-number-font{
  font-size: 24px;
  font-weight: bold;
}
.ml20{
  margin-left: 12px;
}
</style>
